
import React, { useState, useEffect } from "react";
import Moment from 'react-moment';
import NewPinDataService from "../services/new-pin";
import { Link } from "react-router-dom";
import '../App.css'

const Calendar = props => {
  const [pins, setPins] = useState([]);
  const [pins2, setPins2] = useState([]);
 
  const getPin = (by, query) => {
    NewPinDataService.getLaunch()
      .then(response => {
        setPins(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  
  const getPin2 = (by, query) => {
    NewPinDataService.getLaunch2()
      .then(response => {
        setPins2(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getPin();
    getPin2();
  }, []);

let pageTitle = "Calendar";
  
const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  document.title = "FigPinDB | " + pageTitle;

  return(
    <div className="detail-box">
 <h3 align="center">Launch Calendar</h3>
      <ul className="gallery mw-gallery-traditional">
      {
          pins.map(pin => (
            <li key={pin.number} className="gallerybox">
                <div className="pinbox">
                  <Link to={"/pinDetail/"+pin.number}>
                    <div className="thumb"><img alt={pin.name} className="pinlist-image" src={pin.img_url_med}/></div>
                    <div className="pinname">{pin.name}</div>
                    <div className="pinname"><Moment format="MM/DD/YY">{pin.launch_day}</Moment></div>
                  
                    </Link>
                </div>
           </li>
          ))
      }
      </ul>
<br/>
      <div className="home-category">Not Set Yet</div>
      <ul className="gallery mw-gallery-traditional">
      {
          pins2.map(pin => (
            <li key={pin.number} className="gallerybox">
                <div className="pinbox">
                  <Link to={"/pinDetail/"+pin.number}>
                    <div className="thumb"><img alt={pin.name} className="pinlist-image" src={pin.img_url_med}/></div>
                    <div className="pinname">{pin.name}</div>                  
                    </Link>
                </div>
           </li>
          ))
      }
      </ul>
      </div>
);
}
export default Calendar;