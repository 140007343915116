import React from "react";
import { Link } from "react-router-dom";

function Preorders() {
    return (
        <div className="detail-box">
<h3 align="center">Upcoming / Preorders</h3>


{/*
<h4 align="center">There are no FiGPiNs on preorder at this time.</h4>
<h4 align="center">No Preorders at this time.</h4>
<ul className="gallery mw-gallery-traditional-left">
<li className="gallerybox"><div className="newrelease-header">Star Wars<br/>Jan 2022</div></li>
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/795">
    <img className="pinlist-image" src="http://s3.us-east-2.amazonaws.com/figpinimages/FigpinImage/12/b5/da/6905/6905.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/796">
    <img className="pinlist-image" src="http://s3.us-east-2.amazonaws.com/figpinimages/FigpinImage/6d/e9/2d/6909/6909.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>
</ul>
*/}

<div className="home-category">Upcoming</div>
<br/>

<div className="videotitle">Release Date Unknown</div>
<ul className="gallery mw-gallery-traditional-left">   
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/G14">
    <img className="pinlist-image" alt="G14" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/a1/09/af/13572/13572.ce68e513.png"/></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1637">
    <img className="pinlist-image" alt="1637" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/2b/21/7e/12804/12804.ce68e513.png"/></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1366">
    <img className="pinlist-image" alt="1366" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/7d/f5/74/11212/11212.ce68e513.png"/></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1619">
    <img className="pinlist-image" alt="1619" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/17/9c/a8/12969/12969.ce68e513.png"/><div className="pinname">Retailer Exclusive</div></Link></div></li>     
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1560">
    <img className="pinlist-image" alt="1560" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/80/a2/be/11931/11931.ce68e513.png"/><div className="pinname">Retailer Exclusive</div></Link></div></li>   
</ul>
<br/>

<div className="home-category">Preorders</div>
<br/>


<div className="home-category">There are no FiGPiNs on preorder at this time.</div>

        </div>
    );
}

export default Preorders;


